var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-end mx-1"
  }, [_c('BAlert', {
    staticClass: "px-2 py-50 fit-content",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Please check the service fee again\']')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }