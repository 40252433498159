<template lang="">
  <div class="d-flex justify-content-end mx-1">
    <BAlert
      show
      variant="danger"
      class="px-2 py-50 fit-content"
    >
      {{ $t('reservation[\'Please check the service fee again\']') }}
    </BAlert>
  </div>
</template>
<script>
import { BAlert } from 'bootstrap-vue'

export default {
  components: {
    BAlert,
  },
}
</script>
<style lang="">

</style>
